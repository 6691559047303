<template>
  <div class="ct-tm">
    <h1 class="cs-textstyle-page-title">Topic Manager</h1>
    <cs-card>
      <div slot="body">
        <div class="cs-textstyle-page-subtitle">
          Topic Manager coming soon...
        </div>
      </div>
    </cs-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ct-tm >>> .cs-card {
  height: 750px;
  display: grid;
  place-items: center;
}
.cs-textstyle-page-subtitle {
  color: var(--cs-default-base);
}
</style>
