import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
// Topic Manager
import TopicManager from '@/views/topicmanager/TopicManager.vue';
import TopicManagerList from '@/views/topicmanager/TopicManagerList.vue';
import TopicManagerDetail from '@/views/topicmanager/TopicManagerDetail.vue';
// Topic Modeling
import TopicModeling from '@/views/topicmodeling/TopicModeling.vue';
import TopicModelingList from '@/views/topicmodeling/TopicModelingList.vue';
import TopicModelingDetail from '@/views/topicmodeling/TopicModelingDetail.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/topicmanager',
    name: 'TopicManager',
    component: TopicManager,
    redirect: '/topicmanager/list',
    children: [
      {
        path: 'list',
        name: 'TopicManagerList',
        component: TopicManagerList,
        meta: { breadcrumbs: ['Topic Manager'] },
      },
      {
        path: ':id',
        name: 'TopicManagerDetail',
        component: TopicManagerDetail,
        meta: {
          breadcrumbs: ['Topic Manager', `Job #id`],
        },
      },
    ],
  },
  {
    path: '/topicmodeling',
    name: 'TopicModeling',
    component: TopicModeling,
    redirect: '/topicmodeling/list',
    children: [
      {
        path: 'list',
        name: 'TopicModelingList',
        component: TopicModelingList,
        meta: { breadcrumbs: ['Topic Modeling'] },
      },
      {
        path: ':id',
        name: 'TopicModelingDetail',
        component: TopicModelingDetail,
        meta: {
          breadcrumbs: ['Topic Modeling', `Job #id`],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // next();

  if ($auth.isLoggedIn() && to.fullPath.includes('/login')) {
    // Logged in but trying to access an onboarding screen
    next('/');
  } else if (!$auth.isLoggedIn() && !to.fullPath.includes('/login')) {
    // Logged out but trying to access an app screen
    next('/login');
  } else {
    next();
  }
});

export default router;
