<template>
  <div>
    <cs-modal :show="showAdd" class="ct-create-job" @close="onClose">
      <div slot="header">Create New Job</div>
      <div slot="body">
        <div
          class="ct-create-job__tags-container my-2"
          @keydown.enter="onAddTag"
        >
          <cs-input
            v-model="tag_data"
            placeholder="Ex: Liquidation"
            label="Job Queries"
          />
          <cs-button
            icon="cs-icons-add-filled"
            variant="primary"
            fill="outline"
            @click="onAddTag"
            >&nbsp;Add Query</cs-button
          >
          <div
            v-if="tags"
            class="cs-textstyle-paragraph-small ct-create-job__tags"
          >
            <div
              v-for="tag in tags"
              :key="tag"
              class="ct-create-job__tags--tag"
            >
              <cs-tag
                :style="{
                  '--cs-tag-color': '#87939B',
                  '--cs-tag-texcolor': '#ffffff',
                }"
                >{{ tag.toUpperCase() }}&nbsp;<i
                  class="cs-icons-close"
                  @click="onRemoveTag(tag)"
                ></i
              ></cs-tag>
            </div>
          </div>

          <cs-select
            v-model="language"
            :options="languages"
            label="Language"
            class="select--full-width"
          />
          <cs-select
            v-model="country"
            :options="countries"
            label="Country"
            class="select--full-width"
          />

          <div class="ct-create-job__toggles">
            <div>
              <div class="cs-textstyle-form-input-text">Deep Crawl&nbsp;</div>
              <div>
                <cs-toggle :value="deepCrawl"></cs-toggle>
              </div>
            </div>
            <div>
              <div class="cs-textstyle-form-input-text">Crawl News&nbsp;</div>
              <div>
                <cs-toggle :value="crawlNews"></cs-toggle>
              </div>
            </div>
            <div>
              <div class="cs-textstyle-form-input-text">Crawl Web&nbsp;</div>
              <div>
                <cs-toggle :value="crawlWeb"></cs-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <cs-button
        slot="main"
        variant="primary"
        :disabled="!valid || isDisabled"
        @click="createJob"
        >Create</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import CreateClusterJob from '@/gql/topicmodeling/CreateClusterJob.gql';
export default {
  components: {},
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
    codes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      tag_data: '',
      tags: [],
      language: 'english',
      country: null,
      deepCrawl: true,
      crawlNews: true,
      crawlWeb: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isSaving;
    },
    valid() {
      return this.tags.length;
    },
    languages() {
      return this.codes.language.map((language) => {
        return {
          value: language,
          label: language,
        };
      });
    },
    countries() {
      return this.codes.country.map((country) => {
        return {
          value: country,
          label: country,
        };
      });
    },
  },
  methods: {
    onAddTag() {
      if (!this.tag_data) return;
      if (this.tags.find((tag) => tag === this.tag_data)) {
        return;
      }
      this.tags.push(this.tag_data);
      this.tag_data = '';
    },
    onRemoveTag(tag) {
      this.tags = this.tags.filter((v) => v !== tag);
    },
    onClose() {
      this.$emit('close-create-job-modal');
    },
    updateList() {
      this.$emit('created-job');
      this.onClose();
    },
    async createJob() {
      this.isSaving = true;
      await this.$apollo.mutate({
        mutation: CreateClusterJob,
        variables: {
          topicCount: 10,
          country: this.country,
          language: this.language,
          deepCrawl: this.deepCrawl,
          crawlNews: this.crawlNews,
          crawlWeb: this.crawlWeb,
          queries: this.tags,
        },
      });
      this.isSaving = false;
      this.tags = [];
      this.language = 'english';
      this.country = null;
      this.deepCrawl = true;
      this.crawlNews = true;
      this.crawlWeb = false;
      this.$emit('created-job');
      this.$emit('close-create-job-modal');
    },
  },
};
</script>

<style scoped>
.ct-create-job {
  ---cs-modal-width: 600px !important;
}
.ct-create-job__tags-container {
  margin: 20px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}
.ct-create-job__tags-container .cs-button {
  position: absolute;
  right: 20px;
  top: 35px;
}
.ct-create-job__tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.ct-create-job__tags--tag {
  display: flex;
  gap: 1px;
}
.ct-create-job__tags--tag >>> .cs-tag {
  --cs-tag-color: var(--cs-gray-01) !important;
  --cs-tag-text-color: var(--cs-gray-06) !important;
}
.ct-create-job__toggles {
  display: flex;
  justify-content: flex-start;
  gap: 80px;
  padding-left: 5px;
}
.ct-create-job__toggles > div {
  display: flex;
  gap: 5px;
  align-items: center;
}
.select--full-width >>> .cs-select__label {
  width: 100%;
}

@media (max-width: 767px) {
  .ct-create-job__tags-container .cs-button {
    top: 30px;
  }
}
</style>
