<template>
  <div class="ct-tml">
    <h1 class="cs-textstyle-page-title">Topic Modeling</h1>
    <div v-if="$apollo.queries.jobs.loading" class="ct-spinner__wrapper">
      <cs-spinner class="ct-spinner" />
    </div>
    <div v-if="jobs && jobs.length">
      <div class="ct-tml__search">
        <cs-search-bar
          v-model="val"
          class="ct-tml__search-bar"
          placeholder="Search..."
        />
        <div>
          <cs-button
            variant="primary"
            :disabled="disable"
            corners="rounded"
            @click="showAddJob"
            >Create New Job</cs-button
          >
        </div>
      </div>
      <div class="ct-dashboard__table-head">
        <b-table
          :items="jobs"
          :fields="fields"
          :filter="filteredResult"
          table-variant="light"
          tbody-tr-class="ct-dashboard__table-row"
          @row-clicked="myRowClickHandler"
        >
          <template #table-busy>
            <div class="ct-dashboard__table-busy">
              <cs-spinner class="ct-spinner" />
            </div>
          </template>
          <!-- Job ID -->
          <template v-slot:cell(id)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <app-truncated-text slot="visible" :long-text="data.item.id" />
              <div slot="popover" @click="myRowClickHandler(data.item)">
                {{ data.item.id }}
              </div>
            </app-hover>
          </template>
          <!-- Queries -->
          <template v-slot:cell(queries)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <div slot="visible" class="ct-tml__queries">
                <cs-tag
                  v-for="query in data.item.queries.split(',')"
                  :key="query"
                  class="ct-tml__query"
                  >{{ query }}</cs-tag
                >
                <span
                  v-if="data.item.queries && data.item.queries.includes(',')"
                  >...</span
                >
              </div>
              <div slot="popover">
                <cs-tag
                  v-for="query in data.item.queries.split(',')"
                  :key="query"
                  class="ct-tml__query"
                  >{{ query }}</cs-tag
                >
              </div>
            </app-hover>
          </template>
          <!-- Started At -->
          <template v-slot:cell(created_at)="data">
            <span
              >{{
                data.item.created_at | moment('D MMM YYYY, h:mm a')
              }}
              (UTC)</span
            >
          </template>
          <!-- Status -->
          <template v-slot:cell(status)="data">
            <span v-if="data.item.status">
              <cs-tag :class="['ct-tag__' + data.item.status]">{{
                data.item.status | formatTag
              }}</cs-tag>
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Empty State -->
    <div v-if="jobs && !jobs.length">
      <app-empty-state
        :icon="Image"
        button-text="New Job"
        :disable="disable"
        description="You have not created any job yet"
        @add-action="showAddJob"
      />
    </div>
    <app-create-job-modal
      v-if="codes"
      :show-add="showNew"
      :codes="codes"
      @close-create-job-modal="closeAdd"
      @created-job="closeAfterJobCreated"
    />
  </div>
</template>

<script>
import ListClusterJobs from '@/gql/topicmodeling/ListClusterJobs.gql';
import GetClusterEnumCodes from '@/gql/general/GetClusterEnumCodes.gql';
import AppCreateJobModal from '@/components/topicmodeling/CreateJobModal.vue';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppHover from '@/components/general/Hover.vue';

const Image = require('@/assets/images/image.svg');

export default {
  components: {
    AppCreateJobModal,
    AppTruncatedText,
    AppEmptyState,
    AppHover,
  },
  apollo: {
    jobs: ListClusterJobs,
    codes: GetClusterEnumCodes,
  },
  data() {
    return {
      disable: null,
      showNew: false,
      projectId: this.$route.params.id,
      val: '',
      Image,
      fields: [
        {
          key: 'id',
          label: 'Job ID',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'queries',
          label: 'Job Queries',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'language',
          label: 'Language',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'country',
          label: 'Country',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Started At',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'ct-dashboard__table-head',
        },
      ],
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  methods: {
    showAddJob() {
      this.showNew = true;
    },
    closeAdd() {
      this.showNew = false;
    },
    async closeAfterJobCreated() {
      this.showNew = false;
      await this.$apollo.queries.jobs.refetch();
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'TopicModelingDetail',
        params: { id: record.id },
      });
    },
  },
};
</script>

<style scoped>
.ct-tml__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px 20px 0px;
  gap: 20px;
}
.ct-tml__search-bar {
  width: 100%;
}
.ct-tml__search >>> .cs-button {
  width: max-content;
}

.ct-tml__queries {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
  vertical-align: middle;
}
.ct-tml__query {
  --cs-tag-color: var(--cs-gray-01) !important;
  --cs-tag-text-color: var(--cs-gray-06) !important;
  border: 1px solid var(--cs-gray-02);
  margin: 5px;
}
</style>
