<template>
  <div id="navbarAvatar" class="ct-avatar" tabindex="-1">
    <app-avatar size="small" :picture="profilePicture" :name="name">
    </app-avatar>

    <i class="cs-icons-expand-arrow-filled"></i>
    <b-popover
      ref="profilePopover"
      target="navbarAvatar"
      placement="leftbottom"
      triggers="click blur"
      custom-class="bg-dark"
    >
      <div>
        <div class="ct-avatar__body">
          <div class="cs-textstyle-paragraph-small-bold ct-avatar__name">
            {{ name }}
          </div>
          <div class="ct-avatar__item" @click="logout">
            <i class="ch-icons-logout"></i>
            Log Out
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import AppAvatar from '@/components/general/Avatar.vue';

export default {
  components: {
    AppAvatar,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      // TODO: add required:true and remove default
      default: '',
    },
  },
  methods: {
    closeProfilePopover() {
      this.$refs.profilePopover.$emit('close');
    },
    logout() {
      this.$refs.profilePopover.$emit('close');
      localStorage.removeItem('access_token');
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped>
.ct-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.ct-avatar__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--cs-gray-00);
  width: 100% !important;
}
.ct-avatar__item:hover {
  cursor: pointer;
  color: var(--cs-gray-02);
}
.ct-avatar__name {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-bottom: 1px solid var(--cs-gray-05);
  padding: 0em 1em 0.5em 1em;
  margin: 0 -1em;
}
</style>
