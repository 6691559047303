<template>
  <div>
    <cs-modal :show="showModal" class="ct-cluster-detail" @close="onClose">
      <div slot="header">{{ topicNumber }}</div>
      <div slot="body" class="ct-cluster-detail__body">
        <div v-if="terms" class="ct-cluster-detail-terms-container">
          <div class="cs-textstyle-paragraph-small-bold my-3">Keywords</div>
          <div class="cs-textstyle-paragraph-small ct-cluster-detail__tags">
            <div
              v-for="term in terms"
              :key="term.term"
              class="ct-cluster-detail__tags--tag"
            >
              <cs-tag
                :style="{
                  '--cs-tag-color': '#87939B',
                  '--cs-tag-texcolor': '#ffffff',
                }"
                >{{ term.term.toUpperCase() }}</cs-tag
              >
            </div>
          </div>
        </div>
        <div v-if="$apollo.queries.topics.loading" class="ct-spinner__wrapper">
          <cs-spinner class="ct-spinner" />
        </div>
        <div v-else class="mt-2 ct-cluster-detail-url-container">
          <div class="my-3 ct-cluster-detail-url-header">
            <div class="cs-textstyle-paragraph-small-bold">Url</div>
            <div class="cs-textstyle-paragraph-small-bold">Score %</div>
          </div>
          <div class="ct-cluster-detail-url-body">
            <div
              v-for="(topic, index) in topics"
              :key="index"
              class="ct-cluster-detail-url"
            >
              <div>
                <div class="cs-textstyle-paragraph-small-bold">
                  {{ topic.cluster_job_url.title }}
                </div>
                <div class="cs-textstyle-paragraph-small mt-1">
                  {{ topic.cluster_job_url.description }}
                </div>
              </div>
              <a :href="topic.cluster_job_url.url" target="_blank">Link</a>
              <div class="cs-textstyle-paragraph-small-bold">
                {{ Math.round(topic.weight * 100) }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </cs-modal>
  </div>
</template>

<script>
import ListClusterJobUrlTopics from '@/gql/topicmodeling/ListClusterJobUrlTopics.gql';
export default {
  components: {},
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    topicNumber: {
      type: String,
      required: true,
    },
    terms: {
      type: Array,
      required: true,
    },
    jobId: {
      type: String,
      required: true,
    },
    topicId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    topics: {
      query: ListClusterJobUrlTopics,
      variables() {
        return {
          clusterJobId: this.jobId,
          clusterJobTopicId: this.topicId,
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onClose() {
      this.$emit('close-cluster-detail-modal');
    },
  },
};
</script>

<style scoped>
.ct-cluster-detail {
  ---cs-modal-width: 970px !important;
}
.ct-cluster-detail__body {
  min-height: 300px;
}
.ct-cluster-detail-terms-container,
.ct-cluster-detail-url-container {
  margin: 0 10px;
}
.ct-cluster-detail__tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.ct-cluster-detail__tags--tag {
  display: flex;
  gap: 1px;
}
.ct-cluster-detail__tags--tag >>> .cs-tag {
  --cs-tag-color: var(--cs-gray-01) !important;
  --cs-tag-text-color: var(--cs-gray-06) !important;
}

.ct-cluster-detail-url-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ct-cluster-detail-url-body {
  display: flex;
  flex-direction: column;
  background-color: var(--cs-gray-01);
}
.ct-cluster-detail-url {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cs-gray-02);
  padding: 16px;
  gap: 100px;
}
.ct-cluster-detail-url a {
  color: var(--cs-primary-base);
}
</style>
