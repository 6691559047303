<template>
  <b-row class="ct-login">
    <b-col cols="4">
      <div class="ct-login__left">
        <img :src="logo" class="ct-login__logo" />
        <img :src="vector" class="ct-login__vector" />
      </div>
    </b-col>
    <b-col cols="8" class="ct-login__right">
      <div class="ct-login__right--body">
        <cs-card class="ct-login__right--card">
          <div slot="header" class="ct-login__right--title">
            <div class="cs-textstyle-section-heading">Sign In</div>
            <div class="cs-textstyle-paragraph ct-login__info">
              Login with your official contrend credentials.
            </div>
          </div>
          <div slot="body" class="ct-login__right--buttons">
            <cs-login-button
              network="google"
              class="ct-login__button"
              corners="rounded"
              @click="login"
            >
              <div slot="default">Sign in with Google</div>
            </cs-login-button>
          </div>
        </cs-card>
      </div>
      <div class="ct-login__footer">
        <div class="mx-2">
          <a href="mailto:hello@efficientcloud.com">Contact Us</a>
        </div>
        <div class="mx-2">
          <a target="_blank" :href="docsUrl">Help</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
const logo = require('@/assets/images/Logo-white.svg');
const vector = require('@/assets/images/line-pattern.svg');
const tree = require('@/assets/images/tree.svg');
export default {
  data() {
    return {
      logo,
      vector,
      tree,
    };
  },
  computed: {
    docsUrl() {
      return process.env.VUE_APP_DOCS;
    },
  },
  mounted() {
    if (this.$route.hash) {
      const token = this.$route.hash.split('#access_token=')[1];
      if (token) {
        localStorage.access_token = token;
        this.$router.push('/');
        window.location.reload();
      }
    }
  },
  methods: {
    async login() {
      window.location = `${process.env.VUE_APP_REST_ENDPOINT}/login?callback=${window.location.origin}`;
    },
  },
};
</script>

<style scoped>
.ct-login {
  background-color: var(--cs-primary-contrast);
  height: 100vh;
}
.ct-login__left {
  display: flex;
  flex-direction: column;
  background: var(--cs-primary-base);
  height: 100vh;
  justify-content: space-between;
  position: relative;
}
.ct-login__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct-login__vector {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ct-login__right--card {
  width: 450px;
  padding: 10px;
}
.ct-login__info {
  color: var(--cs-gray-05);
  margin-top: 5px;
}
.ct-login__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--cs-accent-dark);
  height: 150px;
}
.ct-login__right--title {
  padding: 10px 20px;
}
.ct-login__right {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  background-image: url('../../assets/images/tree.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
}

.ct-login__right--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.ct-login__right--body >>> .cs-card {
  border: 1px solid var(--cs-default-lightest);
}
.ct-login__right--buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 20px;
}
.ct-login__button {
  --cs-button-padding: 13px !important;
}
.ct-login__footer {
  border-top: 1px solid var(--cs-gray-02);
  display: flex;
  padding: 30px;
  justify-self: flex-end;
  justify-content: center;
  flex-direction: row;
  color: var(--cs-gray-04);
  gap: 30px;
}
.row > * {
  padding: 0px;
}
</style>
