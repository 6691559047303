<template>
  <div class="ct-tmd">
    <div v-if="$apollo.queries.job.loading" class="ct-spinner__wrapper">
      <cs-spinner class="ct-spinner" />
    </div>
    <div v-else>
      <!-- Info -->
      <h1 v-if="jobId" class="cs-textstyle-page-title">{{ jobId }}</h1>
      <div class="ct-tmd__info">
        <div class="ct-tmd__info__content">
          <!-- Job Id -->
          <div class="ct-tmd__info-content">
            <div class="cs-textstyle-paragraph-small-bold">Job Id</div>

            <div class="cs-textstyle-paragraph-small">
              {{ jobId }} <i class="cs-icons-copy" @click="onCopyJobId"></i>
            </div>
          </div>
          <!-- Job Queries -->
          <div class="ct-tmd__info-content">
            <div class="cs-textstyle-paragraph-small-bold">Job Queries</div>
            <div class="cs-textstyle-paragraph-small ct-tmd__queries">
              <cs-tag
                v-for="feature in job.queries.split(',')"
                :key="feature"
                class="ct-tmd__query"
                >{{ feature }}</cs-tag
              >
            </div>
          </div>
          <!-- Job Status -->
          <div class="ct-tmd__info-content">
            <div class="cs-textstyle-paragraph-small-bold">Job Status</div>
            <div class="cs-textstyle-paragraph-small">
              <cs-tag :class="['ct-tag__' + job.status]">{{
                job.status | formatTag
              }}</cs-tag>
            </div>
          </div>
          <!-- Language -->
          <div class="ct-tmd__info-content">
            <div class="cs-textstyle-paragraph-small-bold">Language</div>
            <div class="cs-textstyle-paragraph-small">
              {{ job.language }}
            </div>
          </div>
          <!-- Country -->
          <div class="ct-tmd__info-content">
            <div class="cs-textstyle-paragraph-small-bold">Country</div>
            <div class="cs-textstyle-paragraph-small">
              {{ job.country }}
            </div>
          </div>
          <!-- Initiated At -->
          <div class="ct-tmd__info-content">
            <div class="cs-textstyle-paragraph-small-bold">Initiated At</div>
            <div class="cs-textstyle-paragraph-small">
              {{ job.created_at | moment('DD MMM YYYY, hh:mm A') }}
              (UTC)
            </div>
          </div>
        </div>
        <app-job-info v-if="job" :job="job"></app-job-info>
      </div>
      <h1 v-if="job.topics.length" class="cs-textstyle-section-heading">
        Cluster List
      </h1>
      <div v-if="job.topics.length" class="ct-dashboard__table-head">
        <b-table
          :items="job.topics"
          :fields="fields"
          table-variant="light"
          tbody-tr-class="ct-dashboard__table-row"
          @row-clicked="myRowClickHandler"
        >
          <template #table-busy>
            <div class="ct-dashboard__table-busy">
              <cs-spinner class="ct-spinner" />
            </div>
          </template>
          <!-- Keywords -->
          <template v-slot:cell(terms)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <div slot="visible" class="ct-tmd__terms">
                <cs-tag
                  v-for="term in data.item.terms"
                  :key="term.term"
                  class="ct-tmd__term"
                  >{{ term.term }}</cs-tag
                >
                <span v-if="data.item.terms && data.item.terms.length > 0"
                  >...</span
                >
              </div>
              <div slot="popover">
                <cs-tag
                  v-for="term in data.item.terms"
                  :key="term.term"
                  class="ct-tmd__term"
                  >{{ term.term }}</cs-tag
                >
              </div>
            </app-hover>
          </template>
          <!-- Urls-->
          <template v-slot:cell(urls)="data">
            {{ data.item.top_urls.length }}
          </template>
          <!-- Status -->
          <template v-slot:cell(status)="data">
            <span v-if="data.item.status">
              <cs-tag :class="['ct-tag__' + data.item.status]">{{
                data.item.status | formatTag
              }}</cs-tag>
            </span>
          </template>
        </b-table>
      </div>
      <app-cluster-detail-modal
        :topic-number="topicNumber"
        :terms="topicTerms"
        :job-id="job.id"
        :topic-id="topicId"
        :show-modal="showModal"
        @close-cluster-detail-modal="onCloseModal"
      >
      </app-cluster-detail-modal>
    </div>
  </div>
</template>

<script>
import GetClusterJob from '@/gql/topicmodeling/GetClusterJob.gql';
import AppClusterDetailModal from '@/components/topicmodeling/ClusterDetailModal.vue';
import AppJobInfo from '@/components/topicmodeling/JobInfo.vue';
import AppHover from '@/components/general/Hover.vue';
import { getLastNoOfElements } from '@/services/utils';

export default {
  components: {
    AppJobInfo,
    AppClusterDetailModal,
    AppHover,
  },
  apollo: {
    job: {
      query: GetClusterJob,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  data() {
    return {
      showModal: false,
      topicId: '',
      topicNumber: '',
      topicTerms: [],
      fields: [
        {
          key: 'topic_number',
          label: 'Cluster Name',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'terms',
          label: 'Keywords',
          thClass: 'ct-dashboard__table-head',
        },
        {
          key: 'urls',
          label: 'URLs',
          thClass: 'ct-dashboard__table-head',
        },
      ],
    };
  },
  computed: {
    jobId() {
      return getLastNoOfElements(this.$route.params.id, 12);
    },
  },
  methods: {
    onShowModal() {
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
    },
    onCopyJobId() {
      navigator.clipboard.writeText(this.job.id);
    },
    myRowClickHandler(record) {
      this.topicId = record.id;
      this.topicNumber = record.topic_number;
      this.topicTerms = record.terms;
      this.onShowModal();
    },
  },
};
</script>

<style scoped>
.ct-tmd__info {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-00);
  border-radius: 5px;
  margin-bottom: 45px;
  margin-top: 40px;
  padding: 20px;
}
.ct-tmd__info__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ct-tmd__info__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
}
.ct-tmd__info__top div {
  font-weight: 500;
}
.ct-tmd__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 250px;
}
.ct-tmd__queries {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.ct-tmd__query {
  --cs-tag-color: var(--cs-gray-01) !important;
  --cs-tag-text-color: var(--cs-gray-06) !important;
  border: 1px solid var(--cs-gray-02);
}
.ct-tmd__info-content i.cs-icons-copy {
  color: var(--cs-gray-04);
  font-size: medium;
  cursor: pointer;
}

.ct-tmd__terms {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 600px;
  vertical-align: middle;
}
.ct-tmd__term {
  --cs-tag-color: var(--cs-gray-01) !important;
  --cs-tag-text-color: var(--cs-gray-06) !important;
  border: 1px solid var(--cs-gray-02);
  margin: 5px;
}
</style>
