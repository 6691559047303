<template>
  <div class="ct-tmd-job-info__file">
    <div class="ct-tmd-job-info__file__content">
      <div class="ct-tmd-job-info__info-content">
        <div class="cs-textstyle-paragraph-small-bold">
          Started: {{ job.created_at | moment('DD MMM YYYY, hh:mm A') }} (UTC)
        </div>
        <div>
          <span class="cs-textstyle-body-paragraph"
            >{{ job.completed_percent }}% Complete</span
          >
          <i
            :class="['ct-icon__' + job.status]"
            class="cs-icons-check-success-filled"
          ></i>
        </div>
      </div>

      <b-progress
        :value="job.completed_percent"
        height="7px"
        :max="100"
        class="mb-3 ct-progress"
        :class="['ct-progress__' + job.status]"
      ></b-progress>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.ct-tmd-job-info__file {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
.ct-tmd-job-info__file--heading {
  color: var(--cs-primary-base);
}
.ct-tmd-job-info__file__content {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  padding: 5px 20px;
  gap: 15px;
}

.ct-tmd-job-info__info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ct-tmd-job-info__info-content span {
  color: var(--cs-gray-04);
}
.ct-tmd-job-info__info-content div:last-child {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ct-tmd-job-info__info-content i {
  font-size: 44px;
  color: var(--cs-gray-05);
}

.ct-icon__COMPLETED {
  color: var(--cs-success-base) !important;
}
.ct-icon__COMPLETED,
.ct-icon__CRAWLING_COMPLETED {
  color: var(--cs-success-base) !important;
}
.ct-icon__FAILED {
  color: var(--cs-danger-base) !important;
}
.ct-icon__QUEUED {
  color: var(--cs-info-base) !important;
}
.ct-icon__IN_PROGRESS,
.ct-icon__IN_PROCESS,
.ct-icon__CRAWLING_IN_PROCESS,
.ct-icon__STARTED {
  color: var(--cs-warning-base) !important;
}
.ct-progress >>> .progress-bar {
  background-color: var(--cs-gray-05) !important;
}
.ct-progress__COMPLETED >>> .progress-bar {
  background-color: var(--cs-success-base) !important;
}
.ct-progress__COMPLETED >>> .progress-bar,
.ct-progress__CRAWLING_COMPLETED >>> .progress-bar {
  background-color: var(--cs-success-base) !important;
}
.ct-progress__FAILED >>> .progress-bar {
  background-color: var(--cs-danger-base) !important;
}
.ct-progress__QUEUED >>> .progress-bar {
  background-color: var(--cs-info-base) !important;
}
.ct-progress__IN_PROGRESS >>> .progress-bar,
.ct-progress__IN_PROCESS >>> .progress-bar,
.ct-progress__CRAWLING_IN_PROCESS >>> .progress-bar,
.ct-progress__STARTED >>> .progress-bar {
  background-color: var(--cs-warning-base) !important;
}
</style>
